<template>
  <v-footer>
    <v-container>
      <v-main>
        <v-row>
          <!--<v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1" class="pt-0 pb-0 mb-12 mt-12">
            <v-row class="footer-navigation">
              <v-col cols="12" sm="6" md="3">
                <v-btn color="primary" @click="goToGallery()">
                  <v-icon class="footer-button-icon">{{icons.images}}</v-icon>
                  GALERIE
                  <v-spacer/>
                  <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve" class="footer-arrow">
                    <path style="fill:white;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                          C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                          C31.4,8.2,31.4,7.9,31.3,7.7"/>
                  </svg>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-btn color="primary" @click="goToPress()">
                  <v-icon class="footer-button-icon">{{icons.press}}</v-icon>
                  PRESSE
                  <v-spacer/>
                  <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve" class="footer-arrow">
                    <path style="fill:white;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                          C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                          C31.4,8.2,31.4,7.9,31.3,7.7"/>
                  </svg>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-btn color="primary" @click="goToPartner()">
                  <v-icon class="footer-button-icon">{{icons.handshake}}</v-icon>
                  PARTNER
                  <v-spacer/>
                  <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve" class="footer-arrow">
                    <path style="fill:white;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                          C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                          C31.4,8.2,31.4,7.9,31.3,7.7"/>
                  </svg>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-btn color="primary" @click="goToContact()">
                  <v-icon class="footer-button-icon">{{icons.contact}}</v-icon>
                  KONTAKT
                  <v-spacer/>
                  <svg x="0px" y="0px" viewBox="0 0 32.8 15.8" xml:space="preserve" class="footer-arrow">
                    <path style="fill:white;" d="M31.3,7.7l-6.6-6.6C24.5,1,24.2,1,24,1.1c-0.2,0.2-0.2,0.5,0,0.6l5.9,5.9H1.2
                          C1,7.6,0.8,7.8,0.8,8.1S1,8.5,1.2,8.5h28.6L24,14.4c-0.2,0.2-0.2,0.4,0,0.6c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1l6.6-6.6
                          C31.4,8.2,31.4,7.9,31.3,7.7"/>
                  </svg>
                </v-btn>
              </v-col>
            </v-row>
          </v-col> -->
          <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1" class="pt-0 pb-0 mb-12 mt-12">
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <h3>{{$t('footer.left.title')}}</h3>
                <div v-html="$t('footer.left.description')"/>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <h3>{{$t('footer.right.title')}}</h3>
                <div v-html="$t('footer.right.description')"/>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
    <div class="footer-primary-background">
      <v-container>
        <v-main>
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="10" offset-xl="1">
              <v-row>
                <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="pb-2 text-center text-sm-left">
                  <img @click="goToLanding()" alt="EBWM"
                       src="../../public/images/EBIKE_WORLD_FEDERATION_Logo_Weiss.svg">
                </v-col>
                <v-col cols="12" sm="2" offset-sm="4" md="2" offset-md="6" lg="2" offset-lg="6" xl="2" offset-xl="6"  class="text-center text-sm-right">
                  <v-btn text @click="goToImpressum()">{{$t('press')}}</v-btn>
                </v-col>
                <v-col cols="12" sm="2" md="2" lg="2" xl="2" class="text-center text-sm-right">
                  <v-btn text @click="goToPrivacy()">{{$t('privacy')}}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-main>
      </v-container>
    </div>
  </v-footer>
</template>

<script>
  import {mdiImageMultiple, mdiNewspaperVariantMultipleOutline, mdiHandshake, mdiEmailMultipleOutline}
  from '@mdi/js'
  export default {
    data() {
      return {
        icons: {
          images: mdiImageMultiple,
          press: mdiNewspaperVariantMultipleOutline,
          handshake: mdiHandshake,
          contact: mdiEmailMultipleOutline,
        },
      };
    },
    methods: {
      goToImpressum() {
        this.$router.push({ name: 'impressum' });
      },
      goToPrivacy() {
        this.$router.push({ name: 'privacy' });
      },
      goToLanding() {
        this.$router.push({ name: 'home' });
      },
      goToPartner() {
        this.$router.push({ name: 'partner' });
      },
      goToContact() {
        this.$router.push({ name: 'contact' });
      },
      goToPress() {
        this.$router.push({ name: 'press' });
      },
      goToGallery() {
        this.$router.push({ name: 'gallery' });
      }
    }
  };
</script>

<style lang="scss">
  @import "../assets/scss/variables";
  @import "../assets/scss/layout";
  .v-footer {
    background-color: white !important;
    text-align: left;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    .v-btn {
      text-transform: none;
      font-size: 17px;
    }
    .v-icon {
      color: $primary;
    }
    .footer-primary-background {
      background-color: $primary;
      width: 100%;
      img {
        height: 40px;
      }
      .v-btn {
        color: white;
        text-transform: none;
        font-size: 17px;
        margin-top: 4px;
      }
    }
    .footer-navigation {
      .v-btn {
        font-style: italic;
        width: 100%;
        font-size: 25px;
        height: auto;
        padding-top: 20px;
        padding-bottom: 20px;
        border-radius: 10px;
        box-shadow: 0 3px 6px #00000029;

        .footer-button-icon {
          margin-right: 10px;
          height: 34px;
          width: 34px;
        }
        .footer-arrow {
          height: 34px;
          width: 45px;
          float: right;
        }
        @include MQ(M) {
          font-size: 20px;
          .footer-button-icon {
            margin-right: 7px;
            height: 30px;
            width: 30px;
          }
          .footer-arrow {
            height: 30px;
            width: 38px;
          }
        }
        @include MQ(S) {
          font-size: 20px;
          .footer-button-icon {
            margin-right: 7px;
            height: 30px;
            width: 30px;
          }
          .footer-arrow {
            height: 30px;
            width: 38px;
          }
        }
        @include MQ(XS) {
          font-size: 20px;
          .footer-button-icon {
            margin-right: 7px;
            height: 30px;
            width: 30px;
          }
          .footer-arrow {
            height: 30px;
            width: 38px;
          }
        }
      }
    }
  }
</style>
